<template>
  <page-setup>
    <v-container fluid class="ma-0 pa-0 fill-height px-10 mt-5">
      <v-row dense>
        <v-col>
          <div class="d-flex align-center my-4">
            <div class="text-h5 text-color-main font-weight-black">
              {{ title }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <location-chart-component
            @filter:change="getUserDevotions($event)"
            :locations="userDevotions.locations"
            :total="userDevotions.total_user_devotions"
            :data="userDevotions.data"
            :loading="userDevotions.loading"
            class="my-4"
          >
            <template #title>
              <div class="font-weight-black body-1">
                Daily Devotions
              </div>
            </template>
            <template #subtitle>
              <span class="font-weight-black caption">
                Total # of users doing daily devotions:
              </span>
            </template>
          </location-chart-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <location-chart-component
            @filter:change="getUserBadges($event)"
            :locations="userBadges.locations"
            :total="userBadges.total_user_badges"
            :data="userBadges.data"
            :loading="userBadges.loading"
            class="my-4"
          >
            <template #title>
              <div class="font-weight-black body-1">
                Badges
              </div>
            </template>
            <template #subtitle>
              <span class="font-weight-black caption">
                Total # of badges:
              </span>
            </template>
          </location-chart-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <location-chart-component
            @filter:change="getUserPrayerRequests($event)"
            :locations="userPrayerRequest.locations"
            :total="userPrayerRequest.total_prayer_request_users"
            :data="userPrayerRequest.data"
            :loading="userPrayerRequest.loading"
            class="my-4"
          >
            <template #title>
              <div class="font-weight-black body-1">
                User Prayer Requests
              </div>
            </template>
            <template #subtitle>
              <span class="font-weight-black caption">
                Total # of user prayer requests:
              </span>
            </template>
          </location-chart-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <location-chart-component
            @filter:change="getPrayerRequest($event)"
            :locations="prayerRequest.locations"
            :total="prayerRequest.total_prayer_requests"
            :data="prayerRequest.data"
            :loading="prayerRequest.loading"
            class="my-4"
          >
            <template #title>
              <div class="font-weight-black body-1">
                Prayer Requests
              </div>
            </template>
            <template #subtitle>
              <span class="font-weight-black caption">
                Total # of prayer requests:
              </span>
            </template>
          </location-chart-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <user-goal-chart-component
            @filter:change="getUserGoals($event)"
            :goals="userGoals.goals"
            :total="userGoals.total_users"
            :data="userGoals.data"
            :loading="userGoals.loading"
            class="my-4"
          >
            <template #title>
              <div class="font-weight-black body-1">
                User Goals
              </div>
            </template>
            <template #subtitle>
              <span class="font-weight-black caption">
                Total # of user goals:
              </span>
            </template>
          </user-goal-chart-component>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const locationAppActivitiesRepository = RepositoryFactory.get(
  "location-app-activities"
);

// import { setToUtc, convertUtcToLocal } from "@/services/helpers/date";

export default {
  components: {
    LocationChartComponent: () =>
      import("../components/LocationChartComponent"),
    UserGoalChartComponent: () => import("../components/UserGoalChartComponent")
  },
  data: () => ({
    title: "App Activities",
    userDevotions: {
      locations: [],
      total_user_devotions: 0,
      data: [],
      loading: false
    },
    userBadges: {
      locations: [],
      total_user_badges: 0,
      data: [],
      loading: false
    },
    userPrayerRequest: {
      locations: [],
      total_prayer_request_users: 0,
      data: [],
      loading: false
    },
    prayerRequest: {
      locations: [],
      total_prayer_requests: 0,
      data: [],
      loading: false
    },
    userGoals: {
      goals: [],
      total_users: 0,
      data: [],
      loading: false
    }
  }),
  methods: {
    getUserDevotions(event) {
      // const test = {
      //   date_from: setToUtc(`${event.date_from} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      //   date_to: setToUtc(`${event.date_to} 23:59:59`).format('YYYY-MM-DD HH:mm:ss')
      // }

      // console.log({ test })

      let params = { ...event };

      this.userDevotions.loading = true;

      locationAppActivitiesRepository
        .getUserDevotions({ params })
        .then(response => {
          let {
            data: { responseData: userDevotions }
          } = response;

          this.userDevotions = {
            ...this.userDevotions,
            ...userDevotions
          };
        })
        .catch(() => {
          this.userDevotions = {
            locations: [],
            total_user_devotions: 0,
            data: []
          };
        })
        .finally(() => {
          this.userDevotions.loading = false;
        });
    },
    getUserBadges(event) {
      let params = { ...event };

      this.userBadges.loading = true;

      locationAppActivitiesRepository
        .getUserBadges({ params })
        .then(response => {
          let {
            data: { responseData: userBadges }
          } = response;

          this.userBadges = {
            ...this.userBadges,
            ...userBadges
          };
        })
        .catch(() => {
          this.userBadges = {
            locations: [],
            total_user_badges: 0,
            data: []
          };
        })
        .finally(() => {
          this.userBadges.loading = false;
        });
    },
    getUserPrayerRequests(event) {
      let params = { ...event };

      this.userPrayerRequest.loading = true;

      locationAppActivitiesRepository
        .getUserPrayerRequests({ params })
        .then(response => {
          let {
            data: { responseData: userPrayerRequest }
          } = response;

          this.userPrayerRequest = {
            ...this.userPrayerRequest,
            ...userPrayerRequest
          };
        })
        .catch(() => {
          this.userPrayerRequest = {
            locations: [],
            total_prayer_request_users: 0,
            data: []
          };
        })
        .finally(() => {
          this.userPrayerRequest.loading = false;
        });
    },
    getPrayerRequest(event) {
      let params = { ...event };

      this.prayerRequest.loading = true;

      locationAppActivitiesRepository
        .getPrayerRequest({ params })
        .then(response => {
          let {
            data: { responseData: prayerRequest }
          } = response;

          this.prayerRequest = {
            ...this.prayerRequest,
            ...prayerRequest
          };
        })
        .catch(() => {
          this.prayerRequest = {
            locations: [],
            total_prayer_requests: 0,
            data: []
          };
        })
        .finally(() => {
          this.prayerRequest.loading = false;
        });
    },
    getUserGoals(event) {
      let params = { ...event };

      this.userGoals.loading = true;

      locationAppActivitiesRepository
        .getUserGoals({ params })
        .then(response => {
          let {
            data: { responseData: userGoals }
          } = response;

          this.userGoals = {
            ...this.userGoals,
            ...userGoals
          };
        })
        .catch(() => {
          this.userGoals = {
            locations: [],
            total_users: 0,
            data: []
          };
        })
        .finally(() => {
          this.userGoals.loading = false;
        });
    },
    setUpChartData() {
      this.getUserDevotions();
      this.getUserBadges();
      this.getUserPrayerRequests();
      this.getPrayerRequest();
      this.getUserGoals();
    }
  },
  mounted() {
    // this.setUpChartData();
  }
};
</script>
